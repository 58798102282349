import {Header, Section, SectionsContainer} from 'react-fullpage';
import logo from './logo.svg';
import './App.css';
import Footer from 'react-fullpage/dist/Footer';
import { MainSection } from './sections/MainSection';

function App() {
  
  const options = {
    sectionClassName:     'section',
    anchors:              ['main'],
    scrollBar:            false,
    navigation:           true,
    verticalAlign:        false,
    sectionPaddingTop:    '50px',
    sectionPaddingBottom: '50px',
    arrowNavigation:      true
  };
  return (
    <>
    <SectionsContainer {...options}>
      <Section><MainSection /></Section>
    </SectionsContainer>
    </>
  );
}

export default App;
